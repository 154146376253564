import { IStatsField, IStatsClassification, IStatsStatusClassification, IStatsStatus } from "@/models";
import { BaseRepository } from "@/repositories";

class StatisticsRepository extends BaseRepository<IStatsField | IStatsStatus | IStatsClassification | IStatsStatusClassification> {
    constructor() {
        super("/statistics");
    }

    public async requestsField(payload: Object): Promise<IStatsField[]> {
        return await this.get<IStatsField[]>(`/requests/field`, payload);
    }

    public async requestsStatus(payload: Object): Promise<IStatsStatus[]> {
        return await this.get<IStatsStatus[]>(`/requests/status`, payload);
    }

    public async requestsClassification(payload: Object): Promise<IStatsClassification[]> {
        return await this.get<IStatsClassification[]>(`/requests/classification`, payload);
    }

    public async requestsStatusClassification(payload: Object): Promise<IStatsStatusClassification[]> {
        return await this.get<IStatsStatusClassification[]>(`requests/status/classification`, payload);
    }

    public async clientsField(payload: Object): Promise<IStatsField[]> {
        return await this.get<IStatsField[]>(`/clients/field`, payload);
    }

    public async clientsClassification(payload: Object): Promise<IStatsClassification[]> {
        return await this.get<IStatsClassification[]>(`/clients/classification`, payload);
    }

    public async clientsStatusClassification(payload: Object): Promise<IStatsStatusClassification[]> {
        return await this.get<IStatsStatusClassification[]>(`clients/status/classification`, payload);
    }
}

const statisticsRepository = new StatisticsRepository();

export { StatisticsRepository, statisticsRepository };
