import { Vue, Component } from "vue-property-decorator";
import { IRequestDatatable, IStatsClassification, IStatsField, IStatsStatus } from "@/models";
import { RequestDatatable, ChartBar, ChartPie } from "@/components";
import { Convertors } from "@/helpers";
import { statisticsRepository } from "@/repositories/StatisticsRepository";

@Component({
    components: {
        RequestDatatable,
        ChartBar,
        ChartPie,
    },
    metaInfo: {
        title: "Home",
    },
})

export default class Home extends Vue {

    public loading = false;

    public rFields: IStatsField[] = [];
    public rStatus: IStatsStatus[] = [];
    public rClassifications: IStatsClassification[] = [];

    get rStatsFields(): Object {
        return {
            labels: this.rFields.map(f => f.name),
            datasets: [{
                label: "# of fields requested",
                data: this.rFields.map(f => f.nbItems),
                backgroundColor: this.rFields.map(f => Convertors.convertClassificationToColor(f.classification)),
                borderColor: this.rFields.map(f => Convertors.convertClassificationToColor(f.classification, 1)),
                borderWidth: 1,
            }]
        };
    }

    get rStatsStatus(): Object {
        return {
            labels: this.rStatus.map(f => Convertors.convertRequestStatusToLabel(f.status)),
            datasets: [{
                label: "# of requests",
                data: this.rStatus.map(f => f.nbItems),
                backgroundColor: this.rStatus.map(f => Convertors.convertStatusToColor(f.status)),
                borderColor: this.rStatus.map(f => Convertors.convertStatusToColor(f.status, 1)),
                borderWidth: 1,
            }]
        };
    }

    get rStatsClassifications(): Object {
        return {
            labels: this.rClassifications.map(c => c.classification),
            datasets: [{
                label: "# of classifications requested",
                data: this.rClassifications.map(c => c.nbItems),
                backgroundColor: this.rClassifications.map(c => Convertors.convertClassificationToColor(c.classification)),
                borderColor: this.rClassifications.map(c => Convertors.convertClassificationToColor(c.classification, 1)),
                borderWidth: 1,
            }]
        };
    }

    public headers = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap", value: "snakeAppName", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Requested on", value: "requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public headersSmall = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap small-cell", value: "snakeAppName", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Requested on", value: "requestedOn" },
    ];

    public userRequests: IRequestDatatable[] = [];

    public approverRequests: IRequestDatatable[] = [];

    async mounted(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadUserRequests").then(async (data)=>{
                this.userRequests = data;
                await this.$store.dispatch("app/loadSnakeApps", data.map(r => r.snakeId)).then((snakeApps)=>{
                    this.userRequests.forEach(ur => {
                        const snakeApp = snakeApps.find(sa => sa.snakeId === ur.snakeId);
                        if(snakeApp){
                            ur.snakeAppId = snakeApp.snakeId;
                            ur.snakeAppName = snakeApp.general.name;
                            ur.snakeAppLogo = snakeApp.general.logo;
                            ur.snakeAppIsPlatform = snakeApp.general.isPlatform;
                        }
                    });
                });
            });
            await this.$store.dispatch("app/loadApproverRequests", false).then(async (data)=>{
                this.approverRequests = data;
                await this.$store.dispatch("app/loadSnakeApps", data.map(r => r.snakeId)).then((snakeApps)=>{
                    this.approverRequests.forEach(ar => {
                        const snakeApp = snakeApps.find(sa => sa.snakeId === ar.snakeId);
                        if(snakeApp){
                            ar.snakeAppName = snakeApp.general.name;
                            ar.snakeAppLogo = snakeApp.general.logo;
                            ar.snakeAppIsPlatform = snakeApp.general.isPlatform;
                        }
                    });
                });
            });

            this.rFields = await statisticsRepository.requestsField({ email: this.$store.getters["account/userEmail"] });
            this.rStatus = await statisticsRepository.requestsStatus({ email: this.$store.getters["account/userEmail"] });
            this.rClassifications = await statisticsRepository.requestsClassification({ email: this.$store.getters["account/userEmail"] });
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }
}
