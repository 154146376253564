import { FieldClassifications, RequestStatuses } from "@/enums";

export class Convertors {

    public static convertClassificationToColor(classification: FieldClassifications, opacity: number = 0.5): string {
        switch(classification){
            case FieldClassifications.Green:
                return `rgba(0, 128, 0, ${opacity})`;
            case FieldClassifications.Orange:
                return `rgba(255, 140, 0, ${opacity})`;
            case FieldClassifications.Red:
                return `rgba(255, 0, 0, .9)`;
            default:
                return `rgba(0, 0, 255, ${opacity})`;
        }
    }

    public static convertStatusToColor(status: RequestStatuses, opacity: number = 0.5): string {
        switch(status){
            case RequestStatuses.Rejected:
                return `rgba(0, 0, 0, ${opacity})`;
            case RequestStatuses.Closed:
                return `rgba(255, 0, 255, ${opacity})`;
            case RequestStatuses.AwaitingNewFieldsApproval:
                return `rgba(0, 0, 255, ${opacity})`;
            case RequestStatuses.AwaitingGreenApproval:
                return `rgba(0, 128, 0, ${opacity})`;
            case RequestStatuses.AwaitingOrangeApproval:
                return `rgba(255, 140, 0, ${opacity})`;
            case RequestStatuses.AwaitingRedApproval:
                return `rgba(255, 0, 0, .9)`;
            default:
                return `rgba(0, 0, 255, ${opacity})`;
        }
    }

    public static convertRequestStatusToLabel(status: RequestStatuses): string {
        switch(status){
            case RequestStatuses.Rejected:
                return `Rejected`;
            case RequestStatuses.Closed:
                return `Closed`;
            case RequestStatuses.AwaitingNewFieldsApproval:
                return `Awaiting New Fields Approval`;
            case RequestStatuses.AwaitingGreenApproval:
                return `Awaiting Green Approval`;
            case RequestStatuses.AwaitingOrangeApproval:
                return `Awaiting Orange Approval`;
            case RequestStatuses.AwaitingRedApproval:
                return `Awaiting Red Approval`;
            case RequestStatuses.AwaitingAadAdminsApproval:
                return `Awaiting Aad Admins Approval`;
            default:
                return `Undefined`;
        }
    }
}
